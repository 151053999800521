<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container" :style="`--var-element-color: ${ progress.public_settings.element_color ? progress.public_settings.element_color : '#2f7eed' }; --var-element-text-color: ${ progress.public_settings.element_text_color ? progress.public_settings.element_text_color : '#2c3e50' };`">
            <div class="modal_header border-bottom">
                <h1>Preview <span v-if="history.is_submitted && timezone && (history.submitted_at || history.created_at)" class="submitted-at-badge">Already submitted on {{ moment.utc(history.submitted_at ? history.submitted_at : history.created_at).tz(timezone).format('ll | LT') }} ({{ timezone }})</span></h1>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="submitForm" v-slot="{ errors }" ref="progress-tracking-form-mini" class="need-validation progress-form">
                <div class="modal_body">
                    <div class="setting_wpr">
                        <h3 class="category_title" v-if="progressTracking.data_settings && progressTracking.data_settings.has_progress_question_label">{{ progressTracking.data_settings.progress_question_label }}</h3>
                        <div class="quest_wpr" v-if="!isGoal && progressTracking.data_settings && progressTracking.data_settings.week_enabled">
                            <div class="progress_title"><label>1</label><div class="title">Is this your first progress report?</div></div>
                                <div class="progress_details">
                                <div class="grp_wpr">
                                    <label for="fpr" class="yes">
                                        <Field name="is_first" label="first report" v-model="form.is_first" rules="required">
                                            <input type="radio" name="is_first" value="1" id="fpr" v-model="form.is_first" class="mr-2" hidden>
                                        </Field>
                                        <div class="box_item"><i class="fas fa-thumbs-up mr-2"></i>Yes</div>
                                    </label>
                                    <label for="no_fpr" class="no">
                                        <Field name="is_first" label="first report" v-model="form.is_first" rules="required">
                                            <input type="radio" name="is_first" value="0" id="no_fpr" v-model="form.is_first" class="mr-2" hidden>
                                        </Field>
                                        <div class="box_item"><i class="fas fa-thumbs-down mr-2"></i>No</div>
                                    </label>
                                </div>
                                <ErrorMessage name="is_first" class="text-danger" />
                            </div>
                        </div>
                        <div class="quest_wpr" v-if="form.is_first == 0 && !isGoal  && (progressTracking.data_settings.has_weight || progressTracking.data_settings.has_body_fat || progressTracking.data_settings.has_circumference) && progressTracking.data_settings.week_enabled">
                            <div class="progress_title"><label>1</label><div class="title">Which week of your program are you in?</div></div>
                            <div class="progress_details">  
                                <div class="left_spacing">
                                    <div class="field_area small" :class="{ 'has-error': errors.week }">
                                        <Field type="number" name="week" v-model="form.week" placeholder="1" label="week" rules="required" disabled />
                                    </div>
                                    <ErrorMessage name="week" class="text-danger" />
                                </div>
                            </div>
                        </div>
                        <div class="quest_wpr" v-if="progressTracking.data_settings && progressTracking.data_settings.has_weight">
                            <div class="progress_title"><label>1</label><div class="title">{{ isGoal ? 'What is your desired weight?' : 'What is your weight today?' }}</div></div>
                            <div class="progress_details">
                                <div class="left_spacing">
                                    <div class="field_area small has_suffix" :class="{ 'has-error': errors.weight }">
                                        <Field type="number" name="weight" v-model="form.weight" placeholder="ex: 80" label="weight" rules="required" disabled />
                                        <span class="suffix">{{ progressTracking.data_settings ? progressTracking.data_settings.weight_unit : 'lbs' }}</span>
                                    </div>
                                    <ErrorMessage name="weight" class="text-danger" />
                                </div>
                            </div>
                        </div>
                        <div class="quest_wpr"  v-if="progressTracking.data_settings && progressTracking.data_settings.has_body_fat">
                            <div class="progress_title"><label>1</label><div class="title">{{ isGoal ? 'What is your desired body fat percentage?' : 'What is body fat percentage today?' }}</div></div>
                            <div class="progress_details">
                                <div class="left_spacing">
                                    <div class="field_area small has_suffix" :class="{ 'has-error': errors.body_fat }">
                                        <Field type="number" name="body_fat" v-model="form.body_fat" placeholder="ex: 21" label="body fat" rules="required" disabled />
                                        <span class="suffix">%</span>
                                    </div>
                                    <ErrorMessage name="body_fat" class="text-danger" />
                                </div>
                            </div>
                        </div>
                        <div class="quest_wpr" v-if="progressTracking.data_settings && progressTracking.data_settings.has_circumference">
                            <div class="progress_title"><label>1</label><div class="title">{{ isGoal ? 'What are your desired circumference measurements?' : 'What are your measurements today?' }}</div></div>
                            <div class="progress_details" v-if="progressTracking.data_settings && progressTracking.data_settings.has_circumference">
                                <div class="left_spacing">
                                    <div class="sec_wpr" v-if="progressTracking.data_settings && progressTracking.data_settings.neck">
                                        <h6>Neck</h6>
                                        <div class="field_area small has_suffix" :class="{ 'has-error': errors.neck }">
                                            <Field type="number" name="neck" v-model="form.neck" placeholder="ex: 37" label="neck" rules="required" disabled />
                                            <span class="suffix">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                        <ErrorMessage name="neck" class="text-danger" />
                                    </div>
                                    <div class="sec_wpr" v-if="progressTracking.data_settings && progressTracking.data_settings.shoulders">
                                        <h6>Shoulders</h6>
                                        <div class="field_area small has_suffix" :class="{ 'has-error': errors.shoulders }">
                                            <Field type="number" name="shoulders" v-model="form.shoulders" placeholder="ex: 37" label="shoulders" rules="required" disabled />
                                            <span class="suffix">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                        <ErrorMessage name="shoulders" class="text-danger" />
                                    </div>
                                    <div class="sec_wpr" v-if="progressTracking.data_settings && progressTracking.data_settings.biceps">
                                        <h6>Biceps</h6>
                                        <div class="field_area small has_suffix" :class="{ 'has-error': errors.biceps }">
                                            <Field type="number" name="biceps" v-model="form.biceps" placeholder="ex: 37" label="biceps" rules="required" disabled />
                                            <span class="suffix">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                        <ErrorMessage name="biceps" class="text-danger" />
                                    </div>
                                    <div class="sec_wpr" v-if="progressTracking.data_settings && progressTracking.data_settings.chest">
                                        <h6>Chest</h6>
                                        <div class="field_area small has_suffix" :class="{ 'has-error': errors.chest }">
                                            <Field type="number" name="chest" v-model="form.chest" placeholder="ex: 37" label="chest" rules="required" disabled />
                                            <span class="suffix">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                        <ErrorMessage name="chest" class="text-danger" />
                                    </div>
                                    <div class="sec_wpr" v-if="progressTracking.data_settings && progressTracking.data_settings.belly_button">
                                        <h6>Belly Button</h6>
                                        <div class="field_area small has_suffix" :class="{ 'has-error': errors.belly_button }">
                                            <Field type="number" name="belly_button" v-model="form.belly_button" placeholder="ex: 37" label="belly button" rules="required" disabled />
                                            <span class="suffix">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                        <ErrorMessage name="belly_button" class="text-danger" />
                                    </div>
                                    <div class="sec_wpr" v-if="progressTracking.data_settings && progressTracking.data_settings.waist">
                                        <h6>Waist</h6>
                                        <div class="field_area small has_suffix" :class="{ 'has-error': errors.waist }">
                                            <Field type="number" name="waist" v-model="form.waist" placeholder="ex: 37" label="waist" rules="required" disabled />
                                            <span class="suffix">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                        <ErrorMessage name="waist" class="text-danger" />
                                    </div>
                                    <div class="sec_wpr" v-if="progressTracking.data_settings && progressTracking.data_settings.hips">
                                        <h6>Hips</h6>
                                        <div class="field_area small has_suffix" :class="{ 'has-error': errors.hips }">
                                            <Field type="number" name="hips" v-model="form.hips" placeholder="ex: 37" label="hips" rules="required" disabled />
                                            <span class="suffix">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                        <ErrorMessage name="hips" class="text-danger" />
                                    </div>
                                    <div class="sec_wpr" v-if="progressTracking.data_settings && progressTracking.data_settings.thigh">
                                        <h6>Thigh</h6>
                                        <div class="field_area small has_suffix" :class="{ 'has-error': errors.thigh }">
                                            <Field type="number" name="thigh" v-model="form.thigh" placeholder="ex: 37" label="thigh" rules="required" disabled />
                                            <span class="suffix">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                        <ErrorMessage name="thigh" class="text-danger" />
                                    </div>
                                    <div class="sec_wpr" v-if="progressTracking.data_settings && progressTracking.data_settings.calf">
                                        <h6>Calf</h6>
                                        <div class="field_area small has_suffix" :class="{ 'has-error': errors.calf }">
                                            <Field type="number" name="calf" v-model="form.calf" placeholder="ex: 37" label="calf" rules="required" disabled />
                                            <span class="suffix">{{ progressTracking.data_settings.c_unit }}</span>
                                        </div>
                                        <ErrorMessage name="calf" class="text-danger" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="quest_wpr" v-if="progressTracking.data_settings.has_progress_pics && !isGoal">
                            <div class="progress_title"><label>1</label><div class="title">Are you submitting progress pics with this report?</div></div>
                            <div class="progress_details">
                                <div class="grp_wpr">
                                    <label for="pics" class="yes">
                                        <Field name="has_pics" label="question" v-model="form.has_pics" rules="required">
                                            <input type="radio" name="has_pics" :value="1" id="pics" v-model="form.has_pics" class="mr-2" hidden>
                                        </Field>
                                        <div class="box_item"><i class="fas fa-thumbs-up mr-2"></i>Yes</div>
                                    </label>
                                    <label for="no_pics" class="no">
                                        <Field name="has_pics" label="question" v-model="form.has_pics" rules="required">
                                            <input type="radio" name="has_pics" :value="0" id="no_pics" v-model="form.has_pics" class="mr-2" hidden>
                                        </Field>
                                        <div class="box_item"><i class="fas fa-thumbs-down mr-2"></i>No</div>
                                    </label>
                                </div>
                                <ErrorMessage name="has_pics" class="text-danger" />
                                <div class="picture_section" v-show="form.has_pics == 1 && !isGoal">
                                    <div class="field_area py-4" v-show="progressTracking.data_settings.photo_view.front || progressTracking.data_settings.photo_view.side || progressTracking.data_settings.photo_view.back">
                                        <h6>Captions</h6>
                                        <Field type="textarea" name="caption" v-model="form.caption">
                                            <textarea cols="10" name="caption" rows="10" placeholder="I'm down 15lbs already.." v-model="form.caption" disabled></textarea>
                                        </Field>
                                        <ErrorMessage name="caption" class="text-danger" />
                                    </div>
                                    <div class="upload_image" v-show="progressTracking.data_settings.photo_view.front">
                                        <h6>Front Picture</h6>
                                        <img :src="form.front_photo" />
                                    </div>
                                    <div class="upload_image" v-show="progressTracking.data_settings.photo_view.side">
                                        <h6>Side Picture</h6>
                                        <img :src="form.side_photo" />
                                    </div>
                                    <div class="upload_image" v-show="progressTracking.data_settings.photo_view.back">
                                        <h6>Back Picture</h6>
                                        <img :src="form.back_photo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="progressTracking.data_settings && progressTracking.data_settings.has_custom_questions && questionSections.length" >
                            <div class="mt-2" v-for="(section, s) in questionSections" :key="s">
                                <div v-if="section.enabled && section.questions.length">
                                    <h3 class="category_title">{{ section.title }}</h3>
                                    <div class="quest_panel">
                                        <template v-for="(question, q) in section.questions" :key="q">
                                            <div class="quest_wpr" v-if="!isGoal || (isGoal && question.type == 'assessment' && question.unit != 'BP' && question.unit != 'bp')">
                                                <div class="progress_title"><label>{{q + 1}}</label><div class="title" v-html="isGoal ? question.goal_title : question.title"></div></div>
                                                <div class="progress_details">
                                                    <div class="left_spacing" v-if="question.type == 'checkbox'">
                                                        <div class="question-options">
                                                            <label :for="`styled_radio1-${option.id}`" class="checkbox" v-for="(option, o) in question.options" :key="o">
                                                                <Field :name="`custom_questions_${question.id}_${option.id}`" label="question" v-model="form.custom_answers[question.id][option.id]" :rules="{ required: question.required }">
                                                                    <input type="checkbox" :true-value="option.id" :false-value="0" :id="`styled_radio1-${option.id}`" :name="`custom_questions_${question.id}_${option.id}`" :checked="form.custom_answers[question.id][option.id] == option.id" v-model="form.custom_answers[question.id][option.id]" class="mr-2" hidden>
                                                                </Field>
                                                                <span><i class="fas fa-check"></i></span>
                                                                <p>{{ option.title }}</p>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="left_spacing" v-if="question.type == 'radio'">
                                                        <div class="question-options">
                                                            <label :for="`styled_radio1-${option.id}`" class="radio_opt" v-for="(option, o) in question.options" :key="o">
                                                                <Field :name="`custom_questions_${question.id}`" label="question" v-model="form.custom_answers[question.id]" :rules="{ required: question.required }">
                                                                    <input type="radio" :value="option.id" :name="`custom_questions_${question.id}`" v-model="form.custom_answers[question.id]" :id="`styled_radio1-${option.id}`" class="mr-2" hidden>
                                                                </Field>
                                                                <div class="radio_box">
                                                                    <span class="dot"></span>
                                                                </div>
                                                                <p>{{ option.title }}</p>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div v-if="question.type == 'true-false'">
                                                        <div class="grp_wpr grp_option">
                                                            <label :for="`styled_radio1-${option.id}`" v-for="(option, o) in question.options" :key="o" :class="option.title === 'True' ? 'yes' : 'no'">
                                                                <Field :name="`custom_questions_${question.id}`" label="question" v-model="form.custom_answers[question.id]" :rules="{ required: question.required }">
                                                                    <input type="radio" :value="option.id" :name="`custom_questions_${question.id}`" v-model="form.custom_answers[question.id]" :id="`styled_radio1-${option.id}`" class="mr-2" hidden>
                                                                </Field>
                                                                <div class="box_item"><i :class="`fas fa-thumbs-${option.title == 'True' ? 'up' : 'down'} mr-2`"></i>{{option.title == 'True' ? 'Yes' : 'No'}}</div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="left_spacing" v-if="question.type == 'text'">
                                                        <div class="field_area small" :class="{ 'has-error': errors[`custom_questions_${question.id}`] }">
                                                            <Field type="text" :name="`custom_questions_${question.id}`" label="question" v-model="form.custom_answers[question.id]" :rules="{ required: question.required }" disabled />
                                                        </div>
                                                    </div>
                                                    <div class="upload_image" v-if="question.type == 'file'" :class="{ 'has-error': errors[`custom_questions_${question.id}`] }">
                                                        <Field type="text" :name="`custom_questions_${question.id}`" label="question" v-model="form.custom_answers[question.id]" :rules="{ required: question.required }">
                                                            <img class="" :src="form.custom_answers[question.id]" />
                                                        </Field>
                                                    </div>
                                                    <div class="left_spacing" v-if="question.type == 'textarea'" :class="{ 'has-error': errors[`custom_questions_${question.id}`] }">
                                                        <div class="field_area" :class="{ 'has-error': errors[`custom_questions_${question.id}`] }">
                                                            <Field type="text" :name="`custom_questions_${question.id}`" label="question" v-model="form.custom_answers[question.id]" :rules="{ required: question.required }">
                                                                <textarea cols="30" rows="10" :name="`custom_questions_${question.id}`" v-model="form.custom_answers[question.id]" disabled></textarea>
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div class="left_spacing" v-if="question.type == 'assessment'">
                                                        <div v-if="question.unit == 'BP' || question.unit == 'bp'">
                                                            <div class="field_area small has_suffix" :class="{ 'has-error': errors[`custom_questions_${question.id}`] }">
                                                                <Field type="text" placeholder="120/80" label="question" :name="`custom_questions_${question.id}`" v-model="form.custom_answers[question.id]" disabled />
                                                                <span class="suffix">{{ question.unit }}</span>
                                                            </div>
                                                        </div>
                                                        <div v-else-if="['Pain Index', 'Points', 'Rating', 'Stars'].includes(question.unit)">
                                                            <div class="field_area small has_suffix" :class="{ 'has-error': errors[`custom_questions_${question.id}`] }">
                                                                <Field min="1" max="10" step="1" label="question" :name="`custom_questions_${question.id}`" type="number" v-model="form.custom_answers[question.id]" disabled />
                                                                <span class="suffix">{{ question.unit }}</span>
                                                            </div>
                                                        </div>
                                                        <div v-else class="field_area small has_suffix" :class="{ 'has-error': errors[`custom_questions_${question.id}`] }">
                                                            <Field label="question" :name="`custom_questions_${question.id}`" type="number" min="0" v-model="form.custom_answers[question.id]" disabled />
                                                            <span class="suffix">{{ question.unit }}</span>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage :name="`custom_questions_${question.id}`" class="text-danger" />
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="modal_footer">
                    <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import moment from 'moment-timezone';

    export default {
        name: 'Progress Tracking History',

        data () {
            return  {
                form: {},
                isGoal: false,
                moment,
                timezone: '',
                progress: {
                    public_settings: {},
                    data_settings: {},
                },
            };
        },

        props: {
            modelValue: Boolean,
            history: Object,
            progressTracking: Object,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage
        },

        watch: {
            modelValue () {
                const vm = this;

                vm.progress = JSON.parse(JSON.stringify(vm.progressTracking));
                vm.form = JSON.parse(JSON.stringify(vm.history));

                if (vm.form.submitted_pics == 0) {
                    if (vm.form.front_photo || vm.form.side_photo || vm.form.back_photo) {
                        vm.form.has_pics = 1;
                    }
                }

                if (vm.form.week == 0) {
                    vm.form.is_first = 1;
                } else {
                    vm.form.is_first = 0;
                }

                if (vm.form.is_goal) {
                    vm.isGoal = true;
                } else {
                    vm.isGoal = false;
                }

                if (vm.progressTracking.recurring_settings && vm.progressTracking.recurring_settings.time_zone) {
                    vm.timezone = vm.progressTracking.recurring_settings.time_zone;
                } else {
                    vm.timezone = 'America/Los_Angeles';
                }

                if (vm.progress.public_settings && vm.progress.public_settings.element_text_color) {
                    if (vm.progress.public_settings.element_text_color.toLowerCase() == '#fff') {
                        vm.progress.public_settings.element_text_color = '#2C3E50';
                    } else if (vm.progress.public_settings.element_text_color.toLowerCase() == '#ffffff') {
                        vm.progress.public_settings.element_text_color = '#2C3E50';
                    }
                }
            },

            progressTracking (progressTracking) {
                const vm = this;

                if (progressTracking.recurring_settings && progressTracking.recurring_settings.time_zone) {
                    vm.timezone = progressTracking.recurring_settings.time_zone;
                } else {
                    vm.timezone = 'America/Los_Angeles';
                }

                vm.progress = JSON.parse(JSON.stringify(vm.progressTracking));
                vm.getQuestionSections({ progress_tracking_id: progressTracking.id });

                if (vm.progress.public_settings && vm.progress.public_settings.element_text_color) {
                    if (vm.progress.public_settings.element_text_color.toLowerCase() == '#fff') {
                        vm.progress.public_settings.element_text_color = '#2C3E50';
                    } else if (vm.progress.public_settings.element_text_color.toLowerCase() == '#ffffff') {
                        vm.progress.public_settings.element_text_color = '#2C3E50';
                    }
                }
            },
        },

        computed: mapState({
            questionSections: state => state.progressTrackingModule.questionSections,
        }),

        mounted () {
            const vm = this;

            vm.getQuestionSections({ progress_tracking_id: vm.progressTracking.id });
        },

        methods: {
            ...mapActions({
                getQuestionSections: 'progressTrackingModule/getQuestionSections',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            submitForm () {

            },
        },
    }
</script>

<style scoped>
    .progress-form {
        height: calc(100vh - 160px);
    }

    .progress-form .modal_footer .save_btn {
        background-color: var(--var-element-bg-color, #D4D4D4);
        border-color: var(--var-element-bg-color, #D4D4D4);
        color: var(--var-element-text-color, #2C3E50) !important;
    }

    .form-label {
        background-color: var(--var-element-bg-color, #D4D4D4);
        color: var(--var-element-text-color, #2C3E50) !important;
        padding: 10px;
        text-align: center;
        border-radius: 5px;
        margin-bottom: 9px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }

    .grp_wpr {
        display: flex;
        margin: 0 -10px;
    }

    .box_item {
        flex: 1 0 50%;
        padding: 5px 10px;
    }

    .grp_wpr label {
        /* border: 1px solid #CBCDD3;
          border-radius: 6px;
          padding: 10px 15px;
          display: flex;
          align-items: center; */
    }

    .picture_section {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        padding: 5px 20px !important;
    }

    .picture_section>li {
        padding: 5px 15px;
        width: 33.333%;
    }

    .picture_section>li .section_wpr {
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        display: flex;
        overflow: hidden;
    }

    .picture_section .section_wpr li:not(:last-child) {
        border-right: 1px solid #eaeaea;
    }

    .picture_section .section_wpr li {
        flex: 1;
        line-height: 0;
    }

    .picture_section .section_wpr li .section_title {
        padding: 8px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e9e9e9;
        background: #f9f9f9;
    }

    .picture_section .section_wpr li .section_title h4 {
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
        text-align: left;
        color: #121525;
    }

    .picture_section .section_wpr li .section_title h4 span {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        display: block;
        padding-top: 3px;
        color: #757575;
    }

    .picture_section .section_wpr li .section_title i {
        font-size: 12px;
        width: 25px;
        height: 25px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
        border: 1px solid #e9e9e9;
        border-radius: 50%;
        margin-left: 5px;
        color: #2f7eed;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .quest_panel {
        margin-bottom: 40px;
        padding: 15px;
        background: #fff;
        border-radius: 6px;
        border: 1px solid #f5f5f5;
    }

    .quest_wpr {
        border: 1px solid #e9e9e9;
        padding: 15px 15px 10px 15px;
        border-radius: 6px;
        margin-bottom: 25px;
        background: #fff;
    }

    .progress_title {
        display: flex;
        align-items: flex-start;
        gap: 12px;
    }

    .progress_title .title {
        flex: 1 1 auto;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: var(--var-element-text-color);
        display: block;
    }

    .progress_title label {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid var(--var-element-color);
        color: var(--var-element-color);
        font-size: 9px;
        line-height: 16px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        margin-top: 2px;
    }

    .progress_details {
        padding: 20px 0;
    }

    .left_spacing {
        padding-left: 30px
    }

    .progress_details .field_area {
        margin: 5px 0;
        position: relative;
    }

    .progress_details .field_area input {
        height: 40px;
        width: 100%;
        padding: 0 15px;
        font-size: 11px;
        color: #5a5a5a;
        border: 1px solid #e9e9e9;
        border-radius: 4px;
    }

    .progress_details .field_area.small {
        max-width: 300px;
        width: 100%;
    }

    .progress_details .field_area.small.has_suffix {
        max-width: 300px;
        width: 100%;
    }

    .progress_details .field_area input[type=number]::-webkit-inner-spin-button,
    .progress_details .field_area input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .progress_details .field_area.has_suffix input {
        padding-right: 75px;
    }

    .progress_details .field_area.has_suffix .suffix {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 75px;
        border-left: 1px solid #e9e9e9;
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .progress_details .field_area textarea {
        height: 80px;
        width: 100%;
        padding: 10px;
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        border: 1px solid #e9e9e9;
        border-radius: 4px;
        resize: none;
    }

    .grp_wpr {
        display: flex;
        justify-content: center;
        gap: 8px;
        padding: 5px 0;
    }

    .grp_wpr label .box_item {
        font-size: 13px;
        line-height: 15px;
        border-radius: 4px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.3s ease-in-out;
    }

    .grp_wpr label .box_item i {
        font-size: 15px;
    }

    .grp_wpr label.yes .box_item {
        border: 1px solid #b2eabf;
        background: #effff2;
        color: #23993e;
    }

    .grp_wpr label.no .box_item {
        border: 1px solid #ffb4b4;
        background: #ffecec;
        color: #eb1414;
    }

    .grp_wpr label input[type="radio"]:checked~.box_item {
        opacity: 1;
    }

    .picture_section {
        border-top: 1px dashed #E4E7EC;
        padding: 5px 0;
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .img_wpr {
        display: flex;
        gap: 15px;
    }

    .upload_image {
        margin: 0 0 20px 0;
    }

    .quest_wpr h6 {
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 5px;
    }

    .upload_image :deep(.upload_wpr .upload_area) {
        width: 100%;
        min-height: 120px;
        padding: 0;
        border-color: #d4d4d4;
        background-color: #fbfbfb;
    }

    .upload_image :deep(.upload_wpr .upload_area img:not(.uploaded_img)) {
        max-width: 50px;
    }

    .upload_image :deep(.upload_wpr .upload_area h4) {
        font-size: 11px;
        line-height: 15px;
    }

    .upload_image :deep(.upload_wpr .info) {
        margin: 7px 0 !important;
        font-size: 11px;
        line-height: 14px;
        ;
    }

    .quest_wpr .upload_image :deep(.dropify-wrapper .dropify-message p) {
        font-size: 13px;
        line-height: 18px;
    }

    .sec_wpr {
        padding-bottom: 20px;
    }

    .category_title {
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 500;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .vid_margin {
        margin-bottom: 50px;
        padding: 0;
    }

    .question-options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 5px 0;
        gap: 20px;
    }

    .question-options label {
        display: flex;
        align-items: flex-start;
        cursor: pointer;
    }

    .question-options label p {
        font-size: 11px;
        line-height: 15px;
        padding-left: 10px;
    }

    .question-options .radio_box {
        width: 13px;
        height: 13px;
        /* border: 1px solid var(--var-element-color); */
        border: 1px solid #5a5a5a;
        border-radius: 50%;
        position: relative;
        display: block;
    }

    .question-options .radio_box .dot {
        position: absolute;
        /* background: var(--var-element-color); */
        background: #5a5a5a;
        border-radius: 50%;
        left: 2px;
        right: 2px;
        top: 2px;
        bottom: 2px;
        transform: scale(0);
        transition: all 0.3s ease-in-out;
    }

    .question-options label input[type="radio"]:checked~.radio_box .dot {
        transform: scale(1);
    }

    .question-options label.checkbox span {
        /* border: 1px solid var(--var-element-color); */
        border: 1px solid #5a5a5a;
    }

    .question-options label.checkbox span i {
        /* color: var(--var-element-color);  */
        color: #5a5a5a;
    }

    .upload_image img {
        width: 100%;
        max-width: 150px;
    }

    @media(max-width: 767px) {
        .form-label {
            font-size: 14px;
            line-height: 18px;
        }
    }
</style>
